import fea1 from "../images/featured/01.png";
import fea2 from "../images/featured/02.png";
import fea3 from "../images/featured/03.png";
import fea4 from "../images/featured/04.png";
import fea5 from "../images/featured/05.png";
import fea6 from "../images/featured/06.png";


import finallyimg from "../images/bg-image/finallyimg.jpg";
import profileimg from "../images/bg-image/profile.png";
import signature from "../images/bg-image/signature.png";


function Featured() {

    const featured_data = [
        {img:fea1},
        {img:fea2},
        {img:fea3},
        {img:fea4},
        {img:fea5},
        {img:fea6},
    ]

    return (
      <>
        
        <section className="featured" id="aboutus-sec">
          <div className="container">
            
            {/* <div className="featured-container">
              <div id="featured_featured" className="featured-container__title">
                <h4>FEATURED ON</h4>
              </div>
  
              <div className="featured-container__section">
                <div className="featured-container__section__boxes ">
                  {
                      featured_data.map(data => (
                          <img  loading="lazy"  src={data.img}  alt="featured"  className=""/>
                      )) 
                  }
                </div>
              </div>
            </div> */}


            <div className="finally-sec">
              <div className="finally-sec-text" style={{verticalAlign:'middle'}}>
                <h2>About us</h2>
                <p>
                Lenmac is an agricultural trading company focused on <span>exporting and importing</span> high-quality coffee beans, both  <span>Arabica and Robusta</span>, from Africa, Latin America, and Asia to Europe and North America. We have gained significant attention in the coffee world due to our strong financial capacity, which allows us to provide our customers with the quantity and quality of coffee they desire.

      </p>
      <p> Our main objective is to supply traceable and premium coffee beans to our clients. We also prioritize working directly with smallholder farmers and suppliers, aiming to improve their socio-economic status through Fairtrade and poverty alleviation programs.
</p>
      <p> 
      We are dedicated to continuously improving product quality and providing professional service to ensure customer satisfaction. Our team of industry professionals works tirelessly to find the best solutions for your needs.

      </p>
 
<p>
With our expertise and experience, we offer excellent and prompt services for all your business production requirements

</p>


              </div>

              <div className="finally-sec-image">
                <img loading="lazy" src={finallyimg} alt = "about  me" />
              </div>
            </div>



          </div>
        </section>
      </>
    );
  }
  
  export default Featured;
  